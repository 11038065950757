






































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { debounce } from 'underscore'
import { ValidationObserver } from 'vee-validate'
import BInput from '@/components/validation/BInput.vue'
import BAutocomplete from '@/components/validation/BAutocomplete.vue'
import DialogDelete from '@/components/DialogDelete.vue'
import Toolbar from '@/components/CRUD/Toolbar.vue'

@Component({
  components: {
    'AppForm': ValidationObserver,
    'AppInput': BInput,
    'AppAuto': BAutocomplete,
    'AppDialogDelete': DialogDelete,
    'AppToolbar': Toolbar,
  }
})
export default class ListReturSuratJalan extends Vue {
  //#region COMPUTED
  get loading() {
    return this.$store.getters.loading
  }
  get name() {
    return this.editedItem && this.editedItem.fkTrackingSuratJalan ? this.editedItem.fkTrackingSuratJalan.barcode : ''
  }
  get listCart() {
    return this.$store.getters['scan/returSuratJalan/cart']
  }
  get listSuratJalan() {
    return this.$store.getters.sharedTrackingSuratJalan
  }

  get dataItems() {
    return this.$store.getters['scan/returSuratJalan/data']
  }
  get items() {
    return this.dataItems.data
  }
  get totalItems() {
    return this.dataItems.totalRecords
  }
  get options() {
    return this.$store.getters['scan/returSuratJalan/options']
  }
  set options(newVal) {
    this.$store.dispatch('scan/returSuratJalan/OnOptions', newVal)
  }
  get search() {
    return this.$store.getters['scan/returSuratJalan/search']
  }
  set search(newVal) {
    this.$store.dispatch('scan/returSuratJalan/OnSearch', newVal)
    this.debouncedGetAnswer()
  }

  get formTitle():string {
    return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
  }

  get orderedItems() {
    return this.listCart.filter((x:any) => x.qty > 0)
  }
  //#endregion
  //#region DATA
  $refs!: { observer: InstanceType<typeof ValidationObserver> }
  debouncedGetAnswer:any = null
  headers:Array<object> = [
    { text: '', align: 'start', value: 'data-table-expand' },
    { text: 'Date', value: 'date' },
    { text: 'Surat Jalan', value: 'fkTrackingSuratJalan.barcode' },
    { text: 'Actions', align: 'center', value: 'actions', sortable: false },
  ]
  productHeaders:Array<object> = [
    { text: 'Kode Produk', value: 'itemCode' },
    { text: 'Nama Produk', value: 'itemName' },
    { text: 'Qty', value: 'qty' },
  ]
  footers:object = { itemsPerPageOptions: [ 25, 50, 100 ] }
  expanded:any = []
  dialog:boolean = false
  dialogDelete:boolean = false
  menu:boolean = false
  tab:any = null
  tabItems:any = [ 'Detail', 'Items' ]
  editedIndex:number = -1
  defaultItem:any = {
    date: null,
    trackingSuratJalanId: 0,
    listDetail: []
  }
  editedItem:any = {
    date: null,
    trackingSuratJalanId: 0,
    listDetail: []
  }
  productSearch:any = null
  //#endregion
  //#region WATCH
  @Watch('dialogDelete')
  dialogDeleteChanged(val:boolean) {
    val || this.close()
  }
  //#endregion
  //#region METHODS
  onSearch(value:string) {
    this.search = value
  }
  initialize() {
    if (this.options.page !== 1) this.options.page = 1
    else this.$store.dispatch('scan/returSuratJalan/ReadData')
  }
  editItem(item:any, act:string) {
    if (act === 'edit') {
    } else if (act === 'delete') {
      this.editedItem = item
      this.dialogDelete = true
    }
  }
  newPO() {
    this.$store.dispatch('scan/returSuratJalan/ReadCart')
    this.dialog = true
  }
  confirmDeleteItem(val:boolean) {
    if (val) this.$store.dispatch('scan/returSuratJalan/DeleteData', this.editedItem)
    this.dialogDelete = false
  }
  saveItem() {
    this.$refs.observer.validate()
      .then(success => {
        if (!success) return

        const payload = {
          date: this.editedItem.date,
          trackingSuratJalanId: this.editedItem.trackingSuratJalanId,
          listDetail: this.orderedItems
        }
        this.$store.dispatch('scan/returSuratJalan/CreateData', payload)
        this.close()
      })
  }
  close() {
    this.dialog = false
    this.dialogDelete = false
    this.$nextTick(() => {
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      const resetForm = this.$refs.observer
      if (resetForm) resetForm.reset()
    })
  }
  formatDate(val:any) {
    return this.$options.filters?.custom('DD MMM YYYY', val)
  }
  //#endregion
  //#region CREATED
  created() {
    this.$store.dispatch('ReadSharedTrackingSuratJalan')
  }
  //#endregion
  //#region MOUNTED
  mounted() {
    this.debouncedGetAnswer = debounce(this.initialize, 500)
  }
  //#endregion
}
